import { styled, Flex } from '@acko-tech/building-blocks.ui.common';

export const Wrapper = styled(Flex)`
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    min-height: 250px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 15px 0px;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 20px;
    cursor: pointer;
`;

export const Heading = styled(Flex)`
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 10px;
`;

export const Description = styled(Flex)`
    font-size: 14px;
    margin-bottom: 5px;
    width: 100%;
`;

export const CtaWrapper = styled(Flex)`
    gap: 10px;
    margin-top: 20px;

    & > span:nth-child(1) {
        font-size: 14px;
    }

    & > span:nth-child(2) {
        font-size: 14px;
        color: rgb(72, 144, 226);
    }

    @media screen and (max-width: 769px) {
        flex-direction: column;
    }
`;

export const DescriptionRowWrapper = styled.div`
    display: grid;
    grid-template-columns: 0.8fr 0.2fr;
`;

export const DescriptionWrapper = styled(Flex)`
    width: 100%;
    flex-direction: column;
`;

export const RightArrow = styled(Flex)`
    justify-content: center;
`;

export const ImageWrapper = styled(Flex)`
    background: rgb(232, 232, 232);
    border-radius: 50%;
    height: 40px;
    width: 40px;
`;
