import { styled } from '@acko-tech/building-blocks.ui.common';

export const TabWrapper = styled.div`
    display: flex;
`;

export const Heading = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.5px;
    color: ${(props) => props.color};

    &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: 5px;
        left: 0px;
        bottom: -12px;
        background: ${(props) => props.borderColor};
    }
`;

export const HR = styled.div`
    margin-top: 10px;
    border: 1px solid #d6d9e0;
`;

export const OptionDiv = styled.div`
    ${(props) =>
        !props.singleTab &&
        `
    display: flex;
    flex: 1;
    justify-content: center;`}
`;
