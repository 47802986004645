import { styled, Flex } from '@acko-tech/building-blocks.ui.common';

export const Wrapper = styled(Flex)`
    width: 100%;
    background-color: rgb(0, 0, 0);
    padding: 60px 0px;
    margin-top: 50px;
    width: 100%;
    justify-content: center;

    @media screen and (max-width: 768px) {
        padding: 20px;
    }
`;

export const ContentWrapper = styled(Flex)`
    max-width: 1000px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-top: 40px;
    }
`;

export const AddressWrapper = styled(Flex)`
    flex: 1;
    gap: 5px;
    flex-direction: column;
`;

export const Text = styled(Flex)`
    font-size: 14px;
    color: rgb(252, 252, 252);
    width: 80%;
    margin-bottom: 5px;

    & > span {
        font-weight: bold;
        margin-left: 5px;
    }
`;

export const ContactUsWrapper = styled(Flex)`
    flex: 1;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    justify-content: flex-start;
`;

export const ContactUs = styled(Flex)`
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
`;

export const AboutUs = styled(Flex)`
    flex: 1;
`;
