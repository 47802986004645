import { useEffect, useRef } from 'react';
import Image from 'next/image';
import { getImageBasePath } from '@utils/generics';
import { HR } from '@library/tab/styles';
import * as Styles from './styles';

type Props = {
    isOpen: boolean;
    setNavbarOpen: any;
};

function Navbar({ isOpen, setNavbarOpen }: Props) {
    const navbarRef = useRef<any>(null);

    const handleClickOutsideNavbar = (event: MouseEvent) => {
        if (
            navbarRef.current &&
            !navbarRef.current?.contains(event.target as Node)
        ) {
            setNavbarOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutsideNavbar);
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideNavbar);
        };
    }, []);

    return (
        <Styles.NavbarContainer ref={navbarRef} isOpen={isOpen}>
            <Styles.LoginContainer>
                <div>
                    <Image
                        src={getImageBasePath('/icons/close.svg')}
                        alt={''}
                        width={24}
                        height={24}
                        onClick={() => setNavbarOpen(false)}
                    />
                </div>
                <div
                    onClick={() => {
                        window.location.href = 'https://www.acko.com/login';
                    }}
                >
                    Login
                </div>
            </Styles.LoginContainer>
            <div
                style={{
                    background: 'rgb(212, 211, 206)',
                    margin: '5px 0',
                    height: 1,
                }}
            ></div>
            <Styles.NavList>
                <Styles.NavItem>
                    <Styles.HeadingText
                        style={{ fontSize: 20, marginBottom: 20 }}
                    >
                        MOBILE
                    </Styles.HeadingText>
                    <Styles.NavLink href="https://www.acko.com/help/electronics/before-purchasing-a-plan">
                        Screen Protection
                    </Styles.NavLink>
                    <Styles.NavLink href="https://www.acko.com/help/electronics/before-purchasing-a-plan">
                        Mobile All Round Protection
                    </Styles.NavLink>
                    <Styles.NavLink href="https://www.acko.com/repairs">
                        My Devices
                    </Styles.NavLink>
                </Styles.NavItem>
                <div
                    style={{
                        background: 'rgb(212, 211, 206)',
                        margin: '5px 0',
                        height: 1,
                    }}
                ></div>
                <Styles.NavItem>
                    <Styles.HeadingText
                        style={{ fontSize: 20, marginBottom: 20 }}
                    >
                        APPLIANCES
                    </Styles.HeadingText>
                    <Styles.NavLink href="https://www.acko.com/help/electronics/before-purchasing-a-plan?tab=appliances">
                        Extended Warranty
                    </Styles.NavLink>
                    <Styles.NavLink href="https://www.acko.com/appliances">
                        My Devices
                    </Styles.NavLink>
                </Styles.NavItem>
                <div
                    style={{
                        background: 'rgb(212, 211, 206)',
                        margin: '5px 0',
                        height: 1,
                    }}
                ></div>
                <Styles.NavItem>
                    <Styles.HeadingText
                        style={{ fontSize: 20, marginBottom: 20 }}
                    >
                        GOVERNANCES
                    </Styles.HeadingText>
                    <Styles.HeadingText
                        style={{ marginTop: 10, marginBottom: 20 }}
                    >
                        Disclosures
                    </Styles.HeadingText>
                    <Styles.NavLink href="https://acko-wordpress.s3.ap-south-1.amazonaws.com/governance/Acko+Tech_Annual+Return_+2021-22.pdf">
                        Acko Tech Annual Return 2020 - 21
                    </Styles.NavLink>
                    <Styles.NavLink href="https://acko-wordpress.s3.ap-south-1.amazonaws.com/governance/CSR+Committee+Composition.pdf">
                        CSR Committee Composition
                    </Styles.NavLink>
                    <Styles.NavLink href="https://acko-wordpress.s3.ap-south-1.amazonaws.com/governance/CSR+Policy-Acko+Tech.pdf">
                        Policies
                    </Styles.NavLink>
                </Styles.NavItem>
            </Styles.NavList>
        </Styles.NavbarContainer>
    );
}

export default Navbar;
