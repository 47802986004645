import { styled } from '@acko-tech/building-blocks.ui.common';

export const NavbarContainer = styled.div`
    position: fixed;
    top: 0;
    left: ${({ isOpen }) => (isOpen ? '0' : '-96%')};
    width: 96%;
    height: 100vh;
    background-color: white;
    color: #fff;
    transition: left 0.3s ease;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    z-index: 1000;

    @media screen and (min-width: 768px) {
        display: none;
    }
`;

export const LoginContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;

    & > div:nth-child(1) {
        display: flex;
        flex: 1;
    }

    & > div:nth-child(2) {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        color: rgb(72, 144, 226);
        cursor: pointer;
        font-size: 14px;
    }
`;

export const Title = styled.h2`
    text-align: center;
    margin-bottom: 20px;
`;

export const NavList = styled.ul`
    padding: 0;
`;

export const NavItem = styled.li`
    padding: 15px;
`;

export const NavLink = styled.a`
    font-size: 16px;
    color: rgb(74, 74, 74);
    text-decoration: none;
    display: block;
    margin-bottom: 15px;
`;

export const HeadingText = styled.div`
    display: flex;
    color: rgb(74, 74, 74);
    font-size: 18px;
    margin-bottom: 10px;
`;
