import { useRef, useState, useEffect } from 'react';
import { HR } from '@library/tab/styles';
import * as Styles from '../styles';

function GovernanceOptions() {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const dropdownRef = useRef<any>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const openLink = (link: string) => {
        window.location.href = link;
    };

    const handleMouseEnter = () => {
        setIsDropdownVisible(true);
    };

    return (
        <Styles.Option onClick={handleMouseEnter}>
            {'GOVERNANCE'}
            {isDropdownVisible && (
                <Styles.HoverOption ref={dropdownRef}>
                    <Styles.DisclosuresWrapper>
                        <Styles.HoverOptionHeading>
                            Disclosure
                        </Styles.HoverOptionHeading>
                        <Styles.HeaderOptionText
                            onClick={() =>
                                openLink(
                                    'https://acko-wordpress.s3.ap-south-1.amazonaws.com/governance/Acko+Tech_Annual+Return_+2021-22.pdf'
                                )
                            }
                        >
                            Acko Tech Annual Return 2020-21
                        </Styles.HeaderOptionText>
                        <Styles.HeaderOptionText
                            onClick={() =>
                                openLink(
                                    'https://acko-wordpress.s3.ap-south-1.amazonaws.com/governance/CSR+Committee+Composition.pdf'
                                )
                            }
                        >
                            CSR Committee Composition
                        </Styles.HeaderOptionText>
                    </Styles.DisclosuresWrapper>
                    <HR />
                    <Styles.PolicesWrapper>
                        <Styles.HeaderOptionText
                            onClick={() =>
                                openLink(
                                    'https://acko-wordpress.s3.ap-south-1.amazonaws.com/governance/CSR+Policy-Acko+Tech.pdf'
                                )
                            }
                        >
                            Policies
                        </Styles.HeaderOptionText>
                    </Styles.PolicesWrapper>
                </Styles.HoverOption>
            )}
        </Styles.Option>
    );
}

export default GovernanceOptions;
