import { Flex, styled } from '@acko-tech/building-blocks.ui.common';

export const Wrapper = styled(Flex)`
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background: rgb(245, 245, 245);
    scroll-behavior: smooth;

    @media screen and (max-width: 768px) {
        ${(props) =>
            props.navbarOpen &&
            `
            z-index: 1;
            inset: 0px;
            background-color: rgba(0, 0, 0, 0.54);
            `}
    }
`;

export const HeaderWrapper = styled(Flex)`
    height: auto;
`;

export const BodyWrapper = styled(Flex)`
    flex-direction: column;
`;
