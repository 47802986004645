import {
    AccidentalDamage,
    ElectronicsFailure,
    LiquidDamage,
    ScreenDamage,
} from '@components/mpos/partner/constants';

export const getImageBasePath = (imagePath: string) => {
    const imageStaticPath = process.env.NEXT_ASSET_PREFIX
        ? process.env.NEXT_ASSET_PREFIX.split('/next_assets')[0]
        : '';

    const basePath = imageStaticPath
        ? `${imageStaticPath}/_next_static`
        : '/_next_static';
    if (basePath === '/_next_static') {
        return imagePath;
    }
    return `${basePath}${imagePath}`;
};

export const getTncLink = () => {
    try {
        const staticPath = process.env.NEXT_ASSET_PREFIX
            ? process.env.NEXT_ASSET_PREFIX.split('/next_assets')[0]
            : '';
        return `${staticPath}/_next_static/static/mpos_customer_tnc.html`;
    } catch {
        return `/_next_static/static/mpos_customer_tnc.html`;
    }
};

export const getCoverage = (plan: string) => {
    try {
        if (plan.includes('Screen Damage')) {
            return [ScreenDamage];
        }
        if (plan.includes('Comprehensive')) {
            return [LiquidDamage, AccidentalDamage];
        }
        if (plan.includes('Extended Warranty')) {
            return [ElectronicsFailure];
        }
        return [];
    } catch {
        return [];
    }
};

export const whatsCoverText = (plan: string) => {
    try {
        if (plan?.includes('screen damage')) {
            return 'Screen damage';
        }
        if (plan?.includes('comprehensive')) {
            return 'Screen damage with liquid damage';
        }
        if (plan?.includes('extended warranty') || plan?.includes('_ew')) {
            return 'Electronical & mechanical failures';
        }
        return '-';
    } catch {
        return '-';
    }
};

export const getTitleCase = (str: string) => {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(' ');
};
