import { Flex, styled } from '@acko-tech/building-blocks.ui.common';

export const Wrapper = styled(Flex)`
    padding: 20px 80px;

    @media screen and (max-width: 769px) {
        padding: 16px;
    }
`;

export const HeaderNavOption = styled.div`
    @media screen and (min-width: 768px) {
        display: none;
    }
`;

export const OptionsWrapper = styled(Flex)`
    flex: 3;
    align-items: center;
    gap: 60px;

    @media screen and (max-width: 769px) {
        gap: 10px;
    }
`;

export const Option = styled(Flex)`
    font-size: 14px;
    color: rgb(74, 74, 74);
    cursor: pointer;
    width: max-content;
    position: relative;

    @media screen and (max-width: 769px) {
        display: none;
    }
`;

export const HoverOption = styled(Flex)`
    flex-direction: column;
    position: absolute;
    background: white;
    top: 30px;
    border-radius: 8px;
    width: 300px;
`;

export const DisclosuresWrapper = styled(Flex)`
    flex-direction: column;
    padding: 20px;
`;

export const HoverOptionHeading = styled(Flex)`
    color: rgb(74, 74, 74);
    font-size: 16px;
    margin-bottom: 10px;
`;

export const HeaderOptionText = styled(Flex)`
    font-size: 14px;
    color: rgb(74, 74, 74);
    cursor: pointer;
    margin-top: 20px;
`;

export const PolicesWrapper = styled(Flex)`
    padding: 20px;

    & > div {
        margin-top: 0 !important;
    }
`;

export const LoginWrapper = styled(Flex)`
    flex: 1;
    justify-content: flex-end;
    color: rgb(72, 144, 226);
    cursor: pointer;
    font-size: 14px;
    align-items: center;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;
