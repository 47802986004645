/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import { getImageBasePath } from '@utils/generics';
import * as Styles from './styles';

type Props = {
    image: string;
    heading: string;
    descriptions: string[];
    link: string;
};

function ProductCard({ image, heading, descriptions, link }: Props) {
    const onClick = (e: any) => {
        e.preventDefault();
        window.location.href = link;
    };

    return (
        <Styles.Wrapper onClick={onClick}>
            <img
                src={getImageBasePath(image)}
                alt={''}
                width={150}
                height={100}
                style={{ marginTop: 20 }}
            />
            <Styles.Heading>{heading}</Styles.Heading>
            <Styles.DescriptionRowWrapper>
                <Styles.DescriptionWrapper>
                    {descriptions.map((description, index) => (
                        <Styles.Description key={index}>
                            {description}
                        </Styles.Description>
                    ))}
                </Styles.DescriptionWrapper>
                <Styles.RightArrow>
                    <Styles.ImageWrapper>
                        <Image
                            src={getImageBasePath('/icons/grayRightArrow.svg')}
                            alt={''}
                            width={40}
                            height={20}
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                    </Styles.ImageWrapper>
                </Styles.RightArrow>
            </Styles.DescriptionRowWrapper>
        </Styles.Wrapper>
    );
}

export default ProductCard;
