import { Flex, styled } from '@acko-tech/building-blocks.ui.common';

export const Wrapper = styled(Flex)`
    margin-top: 60px;
    max-width: 1000px;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;

    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }
`;

export const Title = styled(Flex)`
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 60px;
    text-align: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        font-size: 25px;
        margin-bottom: 30px;
    }
`;

export const BenefitsWrapper = styled(Flex)`
    gap: 20px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 20px;
        gap: 40px;
    }
`;

export const BenefitsCard = styled(Flex)`
    flex-direction: column;
    gap: 10px;

    @media screen and (max-width: 768px) {
        gap: 5px;
    }
`;

export const Heading = styled(Flex)`
    flex-direction: column;
    color: rgb(74, 74, 74);
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 5px;
    align-self: center;
    text-align: center;
`;

export const Description = styled(Flex)`
    flex-direction: column;
    color: rgb(128, 128, 128);
    font-size: 14px;
    font-weight: 400;
    align-self: center;
    text-align: center;
`;
