import { useRef, useState, useEffect } from 'react';
import Image from 'next/image';
import { getImageBasePath } from '@utils/generics';
import { HR } from '@library/tab/styles';
import Navbar from './navbar';
import * as Styles from './styles';
import GovernanceOptions from './governance-option';
import RepairsOption from './repairs-option';
import MobileOptions from './mobile-option';
import AppliancesOption from './appliances-option';

type Props = {
    navbarOpen: boolean;
    setNavbarOpen: any;
};

function Header({ navbarOpen, setNavbarOpen }: Props) {
    return (
        <Styles.Wrapper>
            <Navbar isOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
            <Styles.OptionsWrapper>
                <Styles.HeaderNavOption>
                    <Image
                        src={getImageBasePath('/icons/nav-icon.svg')}
                        alt={''}
                        width={24}
                        height={24}
                        onClick={() => setNavbarOpen(true)}
                    />
                </Styles.HeaderNavOption>
                <div>
                    <Image
                        src={getImageBasePath('/images/ackoWhite.svg')}
                        alt={''}
                        width={90}
                        height={28}
                    />
                </div>
                <MobileOptions />
                <AppliancesOption />
                <RepairsOption />
                <GovernanceOptions />
            </Styles.OptionsWrapper>
            <Styles.LoginWrapper
                onClick={() => {
                    window.location.href = '/login';
                }}
            >
                Login
            </Styles.LoginWrapper>
        </Styles.Wrapper>
    );
}

export default Header;
