import { Flex, styled } from '@acko-tech/building-blocks.ui.common';

export const Wrapper = styled(Flex)`
    margin-top: 100px;
    max-width: 1000px;
    flex-direction: column;
    align-items: center;
    align-self: center;

    @media screen and (max-width: 768px) {
        margin-top: 40px;
        padding: 20px;
    }
`;

export const Title = styled(Flex)`
    font-size: 42px;
    font-weight: 700;
    text-align: center;
    line-height: 52px;
    margin-bottom: 60px;

    @media screen and (max-width: 768px) {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        line-height: 30px;
        margin-bottom: 60px;
    }
`;

export const ProductCardWrapper = styled(Flex)`
    gap: 20px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;
