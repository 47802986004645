/* eslint-disable @next/next/no-img-element */
import { getImageBasePath } from '@utils/generics';
import * as Styles from './styles';

const benefits = [
    {
        image: '/images/killer-price.png',
        heading: 'Killer Prices',
        description:
            'Acko gives you the best-in-market prices for all its products.',
    },
    {
        image: '/images/stress-free.png',
        heading: 'Stress-Free Repairs',
        description:
            'Stay calm and book a repair. We’ll take care of everything else.',
    },
    {
        image: '/images/convenient.png',
        heading: 'Terribly Convenient',
        description: 'No paper work! Promise.',
    },
];

function Benefits() {
    return (
        <Styles.Wrapper>
            <Styles.Title>Make the right call with Acko</Styles.Title>
            <Styles.BenefitsWrapper>
                {benefits.map((benefit, index) => (
                    <Styles.BenefitsCard key={index}>
                        <div>
                            <img
                                src={getImageBasePath(benefit.image)}
                                alt={''}
                                width={'auto'}
                                height={'auto'}
                            />
                        </div>
                        <Styles.Heading>{benefit.heading}</Styles.Heading>
                        <Styles.Description>
                            {benefit.description}
                        </Styles.Description>
                    </Styles.BenefitsCard>
                ))}
            </Styles.BenefitsWrapper>
        </Styles.Wrapper>
    );
}

export default Benefits;
