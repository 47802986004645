export enum OrderStatus {
    PAYMENT_SUCCESS_BY_CUSTOMER = 'PAYMENT_SUCCESS_BY_CUSTOMER',
    PAYMENT_SUCCESS_BY_DEALER = 'PAYMENT_SUCCESS_BY_DEALER',
    POLICY_CREATED = 'POLICY_CREATED',
}

export const LiquidDamage = {
    icon: '/icons/mpos/liquidDamage.svg',
    title: 'Liquid damage',
    subTitle: `Coffee spills? No problem, we'll fix that`,
};

export const AccidentalDamage = {
    icon: '/icons/mpos/accidentalDamage.svg',
    title: 'Accidental Damage',
    subTitle: `Dropped your device? We'll fix that too!`,
};

export const ScreenDamage = {
    icon: '/icons/mpos/liquidDamage.svg',
    title: 'Screen damage',
    subTitle: `Cracked your screen? We’ll replace it!`,
};

export const ElectronicsFailure = {
    icon: '/icons/mpos/electronics.svg',
    title: 'Electrical & mechanical failures',
    subTitle: `Device stopped working? We’ll fix it!`,
};
