import ProductCard from './card';
import * as Styles from './styles';

function Products() {
    return (
        <Styles.Wrapper>
            <Styles.Title>{`Don’t let your broken gadget take that smile :) away from you.`}</Styles.Title>
            <Styles.ProductCardWrapper>
                <ProductCard
                    image={'/images/repair-mobile.png'}
                    heading={'Repair Mobile'}
                    descriptions={[
                        'Acko presents the All-Round and Screen Protection Plans!',
                        'Plans that ensure you enjoy your new phone without any worries.',
                    ]}
                    link={'https://www.acko.com/repairs'}
                />
                <ProductCard
                    image={'/images/repair-appliance.png'}
                    heading={'Repair Appliance'}
                    descriptions={[
                        'Acko presents a comprehensive extended warranty plan for your new large appliance or television on Amazon.',
                    ]}
                    link={'https://www.acko.com/appliances'}
                />
            </Styles.ProductCardWrapper>
        </Styles.Wrapper>
    );
}

export default Products;
