import { useRef, useState, useEffect } from 'react';
import * as Styles from '../styles';

function MobileOptions() {
    const [showDropdownOption, setShowDropdownOption] = useState(false);
    const dropdownRef = useRef<any>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setShowDropdownOption(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            console.log('unmount');

            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const openLink = () => {
        window.location.href =
            'https://www.acko.com/help/electronics/before-purchasing-a-plan';
    };

    return (
        <Styles.Option onClick={() => setShowDropdownOption(true)}>
            {'MOBILE'}
            {showDropdownOption && (
                <Styles.HoverOption
                    ref={dropdownRef}
                    style={{
                        width: '260px',
                    }}
                >
                    <Styles.HeaderOptionText
                        style={{
                            padding: 5,
                            marginTop: 10,
                            marginBottom: 5,
                            marginLeft: 10,
                        }}
                        onClick={openLink}
                    >
                        Screen Protection
                    </Styles.HeaderOptionText>
                    <div
                        style={{
                            background: 'rgb(212, 211, 206)',
                            margin: '5px 0',
                            height: 1,
                        }}
                    ></div>
                    <Styles.HeaderOptionText
                        style={{
                            padding: 5,
                            marginTop: 5,
                            marginBottom: 5,
                            marginLeft: 10,
                        }}
                        onClick={openLink}
                    >
                        Mobile All Round Protection
                    </Styles.HeaderOptionText>
                    <div
                        style={{
                            background: 'rgb(212, 211, 206)',
                            margin: '5px 0',
                            height: 1,
                        }}
                    ></div>
                    <Styles.HeaderOptionText
                        style={{
                            padding: 5,
                            marginTop: 5,
                            marginLeft: 10,
                            marginBottom: 10,
                        }}
                        onClick={() => {
                            window.location.href =
                                'https://www.acko.com/repairs';
                        }}
                    >
                        My Devices
                    </Styles.HeaderOptionText>
                </Styles.HoverOption>
            )}
        </Styles.Option>
    );
}

export default MobileOptions;
