import { useState } from 'react';
import Header from './header';
import Products from './products';
import Benefits from './benefits';
import Footer from './footer';
import * as Styles from './styles';

function AckoTechElectronicsLandingHomePage() {
    const [navbarOpen, setNavbarOpen] = useState(false);

    return (
        <Styles.Wrapper navbarOpen={navbarOpen}>
            <Styles.HeaderWrapper>
                <Header navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
            </Styles.HeaderWrapper>
            <Styles.BodyWrapper>
                <Products />
                <Benefits />
                <Footer />
            </Styles.BodyWrapper>
        </Styles.Wrapper>
    );
}

export default AckoTechElectronicsLandingHomePage;
